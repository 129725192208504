import React, { createContext, useState, useEffect } from "react";
import Styled from "styled-components";
import Login from "./pages/Login";
import Toast from "./components/Toast";
import Register from "./pages/Register";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import TermsConditions from "./pages/TermsConditions";
import CookiesPolicy from "./pages/CookiesPolicy";
import Prelaunch from "./pages/Prelaunch";
import { useSelector } from "react-redux";

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

const Container = Styled.div`
height:100vh;

overflow:scroll;
background-color:${(props) => props.bgColor};

&::-webkit-scrollbar{
  display:none;
};`;

export const ContextProvider = createContext(null);

export const App = () => {
  const [theme, setTheme] = useState("dark");
  const user = useSelector((state) => state.user.currentUser);
  const userId = useSelector((state) => state.emailAccess.userId);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const router = createBrowserRouter([
    {
      path: "/dashboard",
      element: user ? (
        userId ? (
          <Home />
        ) : (
          <Navigate replace to="/inbox" />
        )
      ) : (
        <Navigate replace to="/login" />
      ),
    },
    {
      path: "/",
      element: <Prelaunch />,
    },
    {
      path: "/login",
      element: user ? <Navigate replace to="/chats" /> : <Login />,
    },
    {
      path: "/register",
      element: !user ? <Register /> : <Navigate replace to="/chats" />,
    },
    {
      path: "/chats",
      element: !user ? (
        <Navigate replace to="/login" />
      ) : userId ? (
        <Home />
      ) : (
        <Navigate replace to="/inbox" />
      ),
    },
    {
      path: "/inbox",
      element: !user ? <Navigate replace to="/login" /> : <Home />,
    },
    {
      path: "/knowledgebase",
      element: !user ? (
        <Navigate replace to="/login" />
      ) : userId ? (
        <Home />
      ) : (
        <Navigate replace to="/inbox" />
      ),
    },

    {
      path: "/settings",
      element: !user ? <Navigate replace to="/login" /> : <Home />,
    },
    {
      path: "/leads",
      element: !user ? (
        <Navigate replace to="/login" />
      ) : userId ? (
        <Home />
      ) : (
        <Navigate replace to="/inbox" />
      ),
    },
    {
      path: "/calendar",
      element: !user ? (
        <Navigate replace to="/login" />
      ) : userId ? (
        <Home />
      ) : (
        <Navigate replace to="/inbox" />
      ),
    },
    {
      path: "/contacts",
      element: !user ? (
        <Navigate replace to="/login" />
      ) : userId ? (
        <Home />
      ) : (
        <Navigate replace to="/inbox" />
      ),
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/refund-policy",
      element: <RefundPolicy />,
    },
    {
      path: "/terms-conditions",
      element: <TermsConditions />,
    },
    {
      path: "/cookies-policy",
      element: <CookiesPolicy />,
    },

    {
      path: "*",
      element: <div>route not found</div>,
    },
  ]);

  const [alert, setAlert] = useState({
    visible: 0,
    type: "success",
    message: "",
    transform: "translateX(400px)",
    zIndex: "-1",
  });
  const colorTheme =
    theme === "light"
      ? {
          backPri: "#F5F5F5",
          backSec: "#FFFFFF",
          fontPri: "#000000",
          fontSec: "grey",
          mainColor: "rgb(0, 129, 180)",
        }
      : {
          backPri: "#0F1C2E",
          backSec: "#1f2b3e",
          // backPri: "#282828",
          // backSec: "#1A1A1A",
          fontPri: "#FFFFFF",
          fontSec: "#F5F5F5",
          mainColor: "rgb(0, 129, 180)",
        };

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };
  var x;
  var y;
  let z;
  const [toast, setToast] = useState(false);
  const setSecondNotification = async (type, message) => {
    clearTimeout(x);
    // setToast(true);
    setAlert({
      visible: 1,
      type: type,
      message: message,
      transform: "translateX(0px)",
      zIndex: "1",
    });

    x = setTimeout(() => {
      removeNotification();
      clearTimeout(z);
      z = setTimeout(() => {
        setToast(false);
      }, 800);
      // setToast(false);
    }, 3000);
  };
  const setNotification = async (type, message) => {
    clearTimeout(y);
    clearTimeout(z);
    setToast(true);
    y = setTimeout(() => {
      setSecondNotification(type, message);
    }, 800);
  };
  const removeNotification = () => {
    setAlert((item) => {
      return { ...item, transform: "translateX(400px)" };
    });
  };

  const Warning = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          display: "flex",
          backgroundColor: colorTheme.backPri,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 style={{ color: colorTheme.fontPri, fontSize: "40px" }}>
          Please use a desktop to access this website
        </h1>
      </div>
    );
  };
  return (
    <ContextProvider.Provider
      value={{
        theme,
        toggleTheme,
        alert,
        setNotification,
        removeNotification,
        colorTheme,
      }}
    >
      <Container bgColor={colorTheme.backPri}>
        <RouterProvider router={router} />
        {/* <Toast /> */}
        {toast && <Toast />}
        {screenSize.dynamicWidth < 1000 && <Warning />}
      </Container>
    </ContextProvider.Provider>
  );
};

export default App;
