import React, { useContext } from "react";
import { useNylas } from "@nylas/nylas-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ContextProvider } from "../../App";
import Axios from "axios";
import "./connect.css";
import "./index.css";
import Styled from "styled-components";
const Container = Styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  padding:20px;
  height:calc(100vh - 60px);
  top:0px;
`;
const Button = Styled.button`
border:none;
color:white;
cursor:pointer;
background-color:#1A73E8;
&:hover{
    background-color:#1A73AA;
}
padding:7px 20px;
border-radius:5px;
transition:0.2s all ease;
font-size:20px;`;

const Top = Styled.div`
flex:1;
padding:10px 20px;`;
const Span = Styled.span`
font-size:15px;

color:white;`;

const SpanU = Styled.span`
margin-left:30px;
text-decoration:underline;
font-size:15px;
cursor:pointer;
color:white;`;
const Bottom = Styled.div`
flex:1;
padding:10px 20px;`;
const Middle = Styled.div`
flex:1;
padding:10px 20px;`;
const Img = Styled.img`
height:300px;
width:300px;
border-radius:50%;`;
const HeadingContainer = Styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  padding:30px;
  padding-bottom:0px;`;

const Heading = Styled.span`
flex:1;
  font-size:32px;

  margin:0px;
  font-weight:bold;
  color:${(props) => props.colorTheme.fontPri};
  flex:1;`;

const Wrapper = Styled.div`
`;
const Connect = ({ email, setEmail }) => {
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const [agree, setAgree] = React.useState(false);
  const loginUser = async (e) => {
    e.preventDefault();
    if (!agree) {
      setNotification("failure", "Please agree to the terms and conditions");
      return;
    }

    const response = await Axios.get(
      `${process.env.REACT_APP_API_URL}/api/auth/google/oauth2callback`
    );

    window.open(response.data.redirect, "_self");
  };
  console.log(agree);
  return (
    <Container>
      {/* <Top>
        <Img src="static/sophia.jpg" />
      </Top> */}
      {/* <Middle>
        <HeadingContainer>
          <Heading colorTheme={colorTheme}>
            Provide Access to Gmail, Contacts and Calendar
          </Heading>
        </HeadingContainer>
      </Middle> */}
      <div
        style={{
          padding: "20px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Span>
          <input
            type="checkbox"
            value={agree}
            onChange={(e) => setAgree(e.target.checked)}
          />
        </Span>
        <Span>
          I consent to share the following fields - name, job title, company
          website, company description, pain points, value propositions,
          countries targeting, job titles, sectors, and meeting link - with
          OpenAI (GPT-4) for the purpose of generating mails.
        </Span>
      </div>
      <Bottom>
        <form onSubmit={loginUser}>
          <button type="submit" className="login-with-google-btn">
            Continue with Google
          </button>
        </form>
      </Bottom>
      <div style={{ padding: "20px", textAlign: "center" }}>
        <Span>
          (NexgenAI's) use and transfer of information received from Google APIs
          to any other app will adhere to
          <a
            href={
              "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            }
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SpanU>Google API Services User Data Policy</SpanU>
          </a>
          , including the Limited Use requirements.
        </Span>
      </div>
    </Container>
  );
};

export default Connect;
